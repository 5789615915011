export enum ConsentType {
    TERMS_AND_CONDITIONS = 0,
    PERSONAL_DATA_PROCESSING = 1,
    SERVICE_WEB_NOTIFICATIONS = 2,
    SERVICE_EMAIL_NOTIFICATIONS = 3,
    SERVICE_PUSH_NOTIFICATIONS = 4,

    MARKETING_NEWSLETTER_DANE_UCTOVNICTVO = 5,
    MARKETING_NEWSLETTER_MZDY_PERONALISTIKA = 6,
    MARKETING_NEWSLETTER_PRAVO_LEGISLATIVA = 7,
    MARKETING_NEWSLETTER_VEREJNA_SPRAVA = 8,
    MARKETING_NEWSLETTER_BEZPEPCNOST = 9,
    MARKETING_NEWSLETTER_MANAZMENT_PODNIKANIE = 10,
    MARKETING_NEWSLETTER_VZDELAVANIE = 11,
    MARKETING_NEWSLETTER_PP_BONUSY = 12,

    MARKETING_NEWSLETTER_GET_NOTIFS_3DAY_BEFORE = 14,
}
